import { toTheme } from '@theme-ui/typography'
import wp2016 from 'typography-theme-wordpress-2016'
import {merge} from 'theme-ui'

const purple60 = `#663399`
const grey90 = `#232129`
const black80 = `#1B1F23`
const offwhite = `#f3f6fa`
const lightGray = `hsla(0, 0%, 0%, 0.2)`

export default merge(toTheme(wp2016), {
  useBorderBox: false,
  colors: {
    background: offwhite,
    highlight: `#ADD8E6`,
    modes: {
      dark: {
        text: grey90,
        background: offwhite,
        primary: purple60,
        secondary: black80,
        muted: lightGray,
        highlight: `#ADD8E6`,
        heading: grey90,
      },
    },
  },
  styles: {
    blockquote: {
      pl: 2
    },
    pre: {
      margin: `0 0 2 0`
    }
  },
  fonts: {
    body: "system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    heading: "system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif"
  },
})